import { UserStatus } from '@/global/dtos/user.dto';
import { RoleCodes } from '@/global/roles';
import { create } from 'zustand';


interface UserState {
    id: string;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    instaHandle?: string;
    tiktokHandle?: string;
    bio?: string;
    role?: RoleCodes;
    agencyId?: string;
    status?: UserStatus;
    address?: string;
    phone?: string;
    ssn_last_4?: string;

    getUserData: () => {
        id: string;
        displayName: string | undefined;
        firstName: string | undefined;
        lastName: string | undefined;
        email: string | undefined;
        instaHandle: string | undefined;
        tiktokHandle: string | undefined;
        bio: string | undefined;
        role: RoleCodes;
        agencyId: string | undefined;
        status: UserStatus | undefined;
        address: string | undefined;
        phone: string | undefined;
        ssn_last_4: string | undefined;
    };

    setUserData: (user: {
        id?: string;
        displayName: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        instaHandle?: string;
        tiktokHandle?: string;
        bio?: string;
        role?: RoleCodes;
        agencyId?: string;
        status?: UserStatus | undefined;
        address?: string;
        phone?: string;
        ssn_last_4?: string;
    }) => void;
}

const initialState = {
    id: '',
    displayName: '',
    firstName: '',
    lastName: '',
    email: '',
    handle: '',
    bio: '',
    role: undefined,
    agencyId: undefined,
    status: undefined,
    address: undefined,
    phone: undefined,
    ssn_last_4: undefined,
};

const useUserStore = create<UserState>((set, get) => ({
    ...initialState,
    getUserData: () => ({
        id: get().id,
        displayName: get().displayName,
        firstName: get().firstName,
        lastName: get().lastName,
        email: get().email,
        instaHandle: get().instaHandle,
        tiktokHandle: get().tiktokHandle,
        bio: get().bio,
        role: get().role || 2,
        agencyId: get().agencyId,
        status: get().status,
        address: get().address,
        phone: get().phone,
        ssn_last_4: get().ssn_last_4,
    }),

    setUserData: (user: {
        id?: string;
        displayName?: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        instaHandle?: string;
        tiktokHandle?: string;
        bio?: string;
        role?: RoleCodes;
        agencyId?: string;
        status?: UserStatus;
        address?: string;
        phone?: string;
        ssn_last_4?: string;
    }) => set(user),
}));

export default useUserStore;
