import Navbar from "@/components/navbar";
import initFetch from "@/functions/init";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Private: React.FunctionComponent = () => {
  // const accessToken = getAuthState();
  // console.log(accessToken);
  // const userIsLoggedIn = !!accessToken;

  useEffect(() => {
    initFetch().then();
  }, []);

  return (
    <>
      <Navbar />
      <div
        className="content protected"
        onMouseUp={(e) => {
          if (e.target instanceof HTMLElement) {
            const targetedElements: HTMLElement[] = [];
            let currentElement: HTMLElement | null = e.target;

            while (currentElement !== null) {
              targetedElements.push(currentElement);
              currentElement = currentElement.parentElement;
            }
          }
        }}
      >
        <Outlet />
      </div>
    </>
  );
};

export default Private;
