export enum UserStatus {
    NEW = 'NEW', // When the seller is just created (not confirmed)
    CREATED='CREATED', // When user is created by admin, but not confirmed by seller
    INCOMPLETE='INCOMPLETE', //Seller didn't complete his account, bank info is missing
    READY = 'READY', // When seller is approved by Admin
    REJECTED = 'REJECTED', // When seller is rejected by Admin
}

export type UserDto = {
    id: string;
    email: string;
    displayName: string;
    instaHandle?: string;
    tiktokHandle?: string;
    firstName?: string;
    lastName?: string;
    status?: string;
    createdAt: Date;
    updatedAt: Date;
    enabled: boolean;
    role: number;
};