const HANDLINGFEE = 3;
const tier1_lower_limit = 1;
const tier1_higher_limit = 24.99;
const tier2_lower_limit = 25;
const tier2_higher_limit = 49.99;
const tier3_lower_limit = 50;
const tier3_higher_limit = 99.99;
const tier4_lower_limit = 100;
const tier4_higher_limit = 249.99;
const tier5_lower_limit = 250;
const tier5_higher_limit = 999.99;
// const tier6_lower_limit = 1000;

const tier1_commision = 0.2;
const tier2_commision = 0.3;
const tier3_commision = 0.4;
const tier4_commision = 0.5;
const tier5_commision = 0.7;
const tier6_commision = 0.8;

export const calculations = (soldPrice: number) => {
    if (soldPrice >= tier1_lower_limit && soldPrice <= tier1_higher_limit) {
        return roundedNumber((soldPrice - HANDLINGFEE) * tier1_commision);
    } else if (soldPrice >= tier2_lower_limit && soldPrice <= tier2_higher_limit) {
        return roundedNumber((soldPrice - HANDLINGFEE) * tier2_commision);
    } else if (soldPrice >= tier3_lower_limit && soldPrice <= tier3_higher_limit) {
        return roundedNumber((soldPrice - HANDLINGFEE) * tier3_commision);
    } else if (soldPrice >= tier4_lower_limit && soldPrice <= tier4_higher_limit) {
        return roundedNumber((soldPrice - HANDLINGFEE) * tier4_commision);
    } else if (soldPrice >= tier5_lower_limit && soldPrice <= tier5_higher_limit) {
        return roundedNumber((soldPrice - HANDLINGFEE) * tier5_commision);
    } else {
        return roundedNumber((soldPrice - HANDLINGFEE) * tier6_commision);
    }
};

const roundedNumber = (number: number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100
}